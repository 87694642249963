// #region imports
import { React } from 'react'
import {
  Grid,
  Button,
  IconButton
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

// #endregion

// #region style
import { styled } from '@mui/system';
import theme from '../style/mainTheme';


const LoginButton = styled(Button)({
  '&:hover': {
    backgroundColor: 'lightblue'
  },
  width: "120px",
  color: "black",
  textTransform: "none",
  marginRight: "10px"
});

const LogoutButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: 'lightblue'
  },
  color: "black",
  margin: "0px 10px 0px 5px"
});


// #endregion

function LoginAppBar(props) {

  return (
    <Grid container justifyContent="end" alignItems="center">
      {props.user ?
        <>
          {props.user.login}
          <LogoutButton>
            <PowerSettingsNewIcon />
          </LogoutButton>
        </>
        :
        <LoginButton endIcon={<LoginIcon />} onClick={() => { window.location.href = props.SSOUrl }}>
          Connexion
        </LoginButton>}

    </Grid>
  )
}

export default LoginAppBar;