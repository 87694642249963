// #region imports
import { React, useState, useEffect } from 'react'
import axios from 'axios';
import {
  BrowserRouter,
  Route,
  Routes,
  useParams,
  Link,
  useNavigate
} from "react-router-dom";
import {
  Snackbar,
  Alert,
  Button,
  IconButton,
  Grid,
  Drawer,
  Typography,
  Menu,
  MenuItem,
  LinearProgress
} from '@mui/material';

import Home from "./pages/Home";
import Access from "./pages/Access";
import Account from "./pages/Account";
import Reservation from "./pages/Reservation";
import Serie from "./pages/Serie";
import Join from './pages/Join';
import Club from './pages/Club';
import Results from './pages/Results';

import LoginAppBar from "./components/LoginAppBar";
import MenuIcon from '@mui/icons-material/Menu';
// #endregion

// #region style

import { styled } from '@mui/system';
import theme from './style/mainTheme';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Appbar = styled('div')({
  top: '0px',
  position: 'fixed',
  width: "100%",
  height: theme.appBar.height,
  backgroundColor: theme.palette.primary.main
});

const LinkMenuButton = styled(Button)({
  textTransform: 'none',
  margin: '5px 0px',
  color: 'black',
  padding: '8px'
});

const LinkMenuCustom = styled(Link)({
  textDecoration: 'none',
  color: 'black',
  [theme.breakpoints.up('md')]: {
    fontSize: 'medium'
  },
  padding: '0px 10px',
  minWidth: '250px'
});

const LinkCustom = styled(Link)({
  textDecoration: 'none',
  color: 'black',
  '.MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: 'lightblue'
    }
  }
});

const ResponsiveIcon = styled('img')({
  [theme.breakpoints.up('md')]: {
    width: '25px',
    height: '25px',
    margin: '0px 10px'
  },
  width: '20px',
  height: '20px',
  margin: '0px 0px 0px 10px'
})

const PageContainer = styled('div')({
  marginTop: theme.appBar.height,
  padding: "5px",
  [theme.breakpoints.down('md')]: {
    padding: "5px 0px"
  },
})

const MenuButton = styled(Button)({
  height: theme.appBar.height,
  fontSize: 'medium',
  '&:hover': {
    backgroundColor: 'lightblue'
  },
  textTransform: 'none',
  color: 'black',
})

const MenuDivider = styled('div')({
  height: theme.appBar.height,
  backgroundColor: 'black',
  width: '1px'
})

// #endregion

const applicationName = "Archery"
const apiUrl = window.apiUrl
const SSOUrl = window.SSOUrl
const SSOApiUrl = window.SSOApiUrl

export default function App() {
  const [status, setStatus] = useState({})
  const [snackbar, setSnackbar] = useState(false);
  function updateStatus(severity, message) {
    setStatus({ severity, message })
    setSnackbar(true)
  }

  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)

  const [loading, setLoading] = useState(null)

  useEffect(() => {
    if (token) {
      axios.get(SSOApiUrl + "Authenticate?token=" + token + "&application=" + applicationName)
        .then(input => {
          if (input.data.valid) {
            setUser(input.data)
            updateStatus("info", "Bienvenue " + input.data.name)
          } else {
            updateStatus("error", "La validation de l'utilisateur à échouée")
          }
        })
        .catch(() => updateStatus("error", "Une erreur est survenue lors de la récupération de l'utilisateur"))
    }
  }, [token]);

  // #region http handler

  async function httpcall(type, url, payload, callback) {
    var content;
    setLoading(true)
    try {
      switch (type) {
        case "get": {
          content = await axios.get(apiUrl + url, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback(content.data)
          break
        }
        case "post": {
          content = await axios.post(apiUrl + url, payload ? payload : null, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback()
          break
        }
        case "update": {
          content = await axios.update(apiUrl + url, payload ? payload : null, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback()
          break
        }
        case "delete": {
          content = await axios.delete(apiUrl + url, { headers: { token: token } })
            .catch(error => { throw { status: error.response.status, data: error.response.data } });
          callback()
          break
        }
      }
      setLoading(false)
    }
    catch (exception) {
      setLoading(false)
      if (exception.status && exception.status === 401) {
        window.location.href = SSOUrl
      } else if (exception.status && exception.status === 403) {
        updateStatus("warning", "This action is forbiden")
      } else {
        updateStatus("error", "request failed (" + exception.status + ")")
      }
    }
  }

  // #endregion

  const publicLinks = [
    { name: 'Nous Rejoindre', url: '/NousRejoindre' },
    { name: 'Nous Trouver', url: '/NousTrouver' },
    { name: 'Qui Sommes Nous', url: '/QuiSommesNous' }
  ]

  const userLinks = [
    { name: 'Comptage', url: '/Serie' },
    { name: 'Résultats', url: '/Results' },
    // { name: 'Réservation', url: '/Reservation' },
    { name: 'Mes réglages', url: '/Account' }
  ]

  const [dialog, setDialog] = useState(false)
  const [anchorP, setAnchorP] = useState(null)
  const [anchorL, setAnchorL] = useState(null)
  const publicMenu = Boolean(anchorP)
  const privateMenu = Boolean(anchorL)

  //console.log(useMediaQuery(theme.breakpoints.up("md")))

  return (<>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <>
          <Appbar>
            {loading ?
              <LinearProgress color="secondary" sx={{ width: '100%', position: 'absolute', bottom: '0px' }} />
              : <></>}
            <Grid container spacing={0} alignItems='center' justifyContent='space-between'>
              <Grid item>
                {
                  useMediaQuery(theme.breakpoints.up("md")) ?
                    <Grid container spacing={0} alignItems='center'>
                      <ResponsiveIcon src="https://media.tiralarcbondues.fr/assets/bonduestaaLogo.png" />
                      <Typography fontWeight='bold' sx={{ marginRight: '5px' }}>
                        - Club des archers de Bondues
                      </Typography>
                      <MenuDivider />
                      <MenuButton>
                        <LinkCustom to='/' rel="canonical">
                          Accueil
                        </LinkCustom>
                      </MenuButton>
                      <MenuDivider />
                      <MenuButton onClick={(event) => setAnchorP(event.currentTarget)} >
                        Le Club
                      </MenuButton>
                      <Menu open={publicMenu} variant="menu" onClose={() => setAnchorP(null)} anchorEl={anchorP}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                        {publicLinks.map(link => (
                          <MenuItem key={link.name}>
                            <LinkCustom to={link.url} onClick={() => setAnchorP(null)}>
                              {link.name}
                            </LinkCustom>
                          </MenuItem>
                        ))}
                      </Menu>
                      {user && user.rightLevel <= 2 ?
                        <>
                          <MenuDivider />
                          <MenuButton onClick={(event) => setAnchorL(event.currentTarget)} >
                            Espace Licencié
                          </MenuButton>
                          <Menu open={privateMenu} variant="menu" onClose={() => setAnchorL(null)} anchorEl={anchorL}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                            {userLinks.map(link => (
                              <MenuItem key={link.name}>
                                <LinkCustom to={link.url} onClick={() => setAnchorL(null)}>
                                  {link.name}
                                </LinkCustom>
                              </MenuItem>
                            ))}
                          </Menu>
                        </>
                        : <></>}
                    </Grid>
                    :
                    <IconButton onClick={() => setDialog(true)} sx={{ margin: '5px' }}>
                      <MenuIcon />
                    </IconButton>
                }
              </Grid>
              <Grid item>
                <LoginAppBar SSOUrl={SSOUrl} user={user} />
              </Grid>
            </Grid>
          </Appbar>
          <PageContainer>
            <Routes>
              <Route path="/Token/:token" element={<TokenHandler setter={setToken} />} />
              <Route path="/NousTrouver" element={<Access statusUpdate={updateStatus} httpcall={httpcall} />} />
              <Route path="/NousRejoindre" element={<Join statusUpdate={updateStatus} httpcall={httpcall} />} />
              <Route path="/QuiSommesNous" element={<Club statusUpdate={updateStatus} httpcall={httpcall} />} />
              {user && user.rightLevel <= 2 ?
                <>
                  {/* <Route path="/Reservation" element={<Reservation statusUpdate={updateStatus} httpcall={httpcall} user={user} />} /> */}
                  <Route path="/Serie" element={<Serie statusUpdate={updateStatus} httpcall={httpcall} />} />
                  <Route path="/Account" element={<Account statusUpdate={updateStatus} httpcall={httpcall} />} />
                  <Route path="/Results" element={<Results statusUpdate={updateStatus} httpcall={httpcall} user={user} />} />
                </>
                : <></>}
              <Route path="/" element={<Home statusUpdate={updateStatus} httpcall={httpcall} />} />
              <Route path="*" element={<Home statusUpdate={updateStatus} httpcall={httpcall} />} />
            </Routes>
          </PageContainer>
        </>
        {useMediaQuery(theme.breakpoints.down("md")) ?
          <Drawer anchor='left' open={dialog} onClose={() => setDialog(false)}>
            <Typography gutterBottom fontStyle='italic' variant='h6' textAlign='center'>
              Menu
            </Typography>
            <Grid container direction='column'>
              <LinkMenuButton variant='outlined'>
                <LinkMenuCustom to='/' rel="canonical" onClick={() => setDialog(false)}>
                  Accueil
                </LinkMenuCustom>
              </LinkMenuButton>
              {publicLinks.map(link => (
                <LinkMenuButton key={link.name} variant='outlined'>
                  <LinkMenuCustom to={link.url} onClick={() => setDialog(false)}>
                    {link.name}
                  </LinkMenuCustom>
                </LinkMenuButton>
              ))}
              {user && user.rightLevel <= 2 ?
                <>
                  {userLinks.map(link => (
                    <LinkMenuButton key={link.name} variant='outlined'>
                      <LinkMenuCustom to={link.url} onClick={() => setDialog(false)}>
                        {link.name}
                      </LinkMenuCustom>
                    </LinkMenuButton>
                  ))}
                </>
                : <></>}
            </Grid>
          </Drawer>
          : <></>}
      </ThemeProvider>
    </BrowserRouter>
    <Snackbar
      open={snackbar}
      autoHideDuration={2500}
      onClose={() => { setSnackbar(false) }}
      anchorOrigin={{ vertical: useMediaQuery(theme.breakpoints.up("md")) ? 'top' : 'bottom', horizontal: 'center' }}>
      <Alert severity={status.severity ? status.severity : "error"} variant="filled">
        {status.message ? status.message : "error, no data transmited to this box"}
      </Alert>
    </Snackbar>
  </>)
}

const TokenHandler = (props) => {
  let { token } = useParams()
  const navigate = useNavigate()
  const [tempToken, setTempToken] = useState(null)

  useEffect(() => {
    if (tempToken == null) {
      setTempToken(token)
    } else {
      props.setter(tempToken)
      navigate("/")
    }
  }, [tempToken]);

  return (
    <></>
  )
}
