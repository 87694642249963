// #region imports
import { React } from 'react'
import {
  Grid,
  Box,
  Typography
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';

// #endregion

// #region style
import { styled } from '@mui/system';

const ScheduleTypography = styled(Typography)({
  marginBottom: '0px'
});

// #endregion

function Schedule(props) {

  const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

  return (
    <Grid container direction="column">
        <Typography variant="h5" color="primary">
          Horaires
        </Typography>
        <Grid container direction="column">
          {days.map((day, index) => (
            <div key={day}>
              {props.schedules.filter(date => date.day === index).length > 0 ?
                <>
                  <Box sx={{ fontStyle: 'italic' }}>
                    {day}
                  </Box>
                  {props.schedules.filter(date => date.day === index).map(date => (
                    <ScheduleTypography key={date.id}>
                      {date.time}
                    </ScheduleTypography>
                  ))}
                </> : <></>}</div>))}
        </Grid>
    </Grid>
  )
}

export default Schedule;