// #region imports
import { React, useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  Paper
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { GridRow, CardCustom } from '../style/componentsTheme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Done, DeleteOutline } from '@mui/icons-material';
// #endregion

// #region style
import { styled } from '@mui/system';
import theme from '../style/mainTheme';
import { propsToClassKey } from '@mui/styles';

// #endregion

const ArrowColors = ['#7A0086', 'white', 'white', '#575659', '#575659', '#0389FC', '#0389FC', 'red', 'red', '#FFE703', '#FFE703']

function Results(props) {

  useEffect(() => {
    getResults()
  }, []);

  const [results, setResults] = useState(null)
  const [privateOnly, setPrivateOnly] = useState(true)
  const [date, setDate] = useState(new moment().add(-1, 'M'))

  function getResults() {
    props.httpcall("get", "Result/Get?isPrivate=" + privateOnly + "&date=" + moment(date).format('YYYY-MM-DD'), null, (payload) => callbackGetResults(payload))
  }

  function callbackGetResults(payload) {
    for (let i = 0; i < payload.length; i++) {
      payload[i].totalPoint = payload[i].arrows.reduce((accumulator1, a1) => accumulator1 + a1.reduce((accumulator2, a2) => accumulator2 + a2, 0), 0)
      payload[i].serieAverage = (payload[i].totalPoint / payload[i].arrows.length).toFixed(1)
      payload[i].arrowAverage = (payload[i].totalPoint / payload[i].arrows.length / 3).toFixed(1)
    }

    setResults(payload)
  }

  return (
    <Grid container justifyContent="center">
      <GridRow item md={12} xs={12}>
        <CardCustom sx={{ marginBottom: '10px' }}>
          <Grid container>
            <Grid item xs={5}>
              <Checkbox defaultChecked size="large" value={privateOnly} onChange={(newValue) => setPrivateOnly(newValue.target.checked)} />
              Afficher seulement mes séries
            </Grid>
            <Grid item xs={5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker label="Date de départ" disableFuture value={date} onChange={(newValue) => setDate(newValue)} />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <IconButton variant="filled" color="secondary" size="large" onClick={getResults}>
                <Done />
              </IconButton>
            </Grid>
          </Grid>
        </CardCustom>
      </GridRow>
      <GridRow item xs={12}>
        {results != null ?
          <>
            {results.map(serie => (
              <CardCustom key={serie.id} sx={{ marginBottom: '10px', position: 'relative' }}>
                {serie.owner === props.user.login ?
                  <IconButton sx={{ color: 'red' }} onClick={() => { props.httpcall("delete", "Result/Delete/" + serie.id, null, () => getResults()) }} style={{ position: 'absolute', right: '30px', top: '70px' }}>
                    <DeleteOutline />
                  </IconButton>
                  : <></>}
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant='h5' fontWeight='bold'>
                      {moment(serie.date).format('DD/MM/YYYY')} {serie.location ? ' - ' + serie.location : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography align='right'>
                      {serie.totalPoint} / {serie.arrows.length * 30}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant='h6' sx={{ whiteSpace: "pre-wrap" }}>
                      {serie.description != null || serie.description !== '' ? serie.description : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography align='right' fontWeight='bold'>
                      de {serie.owner}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      volée moyenne :
                      <Typography fontWeight='bold'>
                        {serie.serieAverage} / 30
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      flèche moyenne :
                      <Typography fontWeight='bold'>
                        {serie.arrowAverage} / 10
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '15px' }}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Résultats détaillés</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size="small" >
                          <TableHead sx={{ backgroundColor: 'lightblue' }}>
                            <TableRow>
                              <TableCell>
                                N°
                              </TableCell>
                              <TableCell align="center">
                                F 1
                              </TableCell>
                              <TableCell align="center">
                                F 2
                              </TableCell>
                              <TableCell align="center">
                                F 3
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {serie.arrows.map((serie, index) => (
                              <TableRow key={index} sx={{ backgroundColor: 'white' }}>
                                <TableCell>
                                  {index + 1}
                                </TableCell>
                                {serie.map((arrow, index) => (
                                  <TableCell key={index} align="center" sx={{ backgroundColor: ArrowColors[arrow] }}>
                                    {arrow}
                                  </TableCell>
                                ))}
                                <TableCell align="center">
                                  {serie[0] + serie[1] + serie[2]}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </CardCustom>
            ))}
          </>
          :
          <>
            Pas de volées à Afficher
          </>}
      </GridRow>
    </Grid>
  )
}

export default Results
