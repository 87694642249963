// #region imports
import { React, useState, useEffect } from 'react'
import {
  Button,
  Grid,
  TextField,
  Typography,
  Slider
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { GridRow, CardCustom } from '../style/componentsTheme';

// #endregion

// #region style
import { styled } from '@mui/system';
import theme from '../style/mainTheme';
import { ConstructionOutlined } from '@mui/icons-material';

const FormTextfield = styled(TextField)({
  margin: '20px 0px'
});

// #endregion

function Join(props) {

  const [doOnce, setDoOnce] = useState(true)

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
    }
  }, [doOnce]);

  const [name, setName] = useState("")
  const [age, setAge] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")

  function sendMeetupRequest() {
    props.httpcall("post", "Notification/SendMeetupRequest", { name, email, age, description, phone }, callbackMeetup)
  }

  function callbackMeetup() {
    setName("")
    setAge("")
    setEmail("")
    setDescription("")
    setPhone("")
    props.statusUpdate("success", "Nous avons bien recu votre demande, nous vous recontacterons au plus tôt :)")
  }

  function validateEmail(input) {
    var re = /\S+@\S+\.\S+/;
    return !re.test(input);
  }

  return (
    <Grid container justifyContent="center">
      <GridRow item md={10} xs={12}>
        <CardCustom>
          <Grid container direction="column">
            <Typography variant="h4" fontWeight="bold">
              Nous rejoindre
            </Typography>
            <p>
              Les inscriptions se font tout au long de l'année, il n'est pas nécessaire de s'inscrire en début d'année.<br />
              La fiche d'inscription, ainsi que les tarifs sont disponibles : <br/>
              - <a style={{ fontWeight: 'bold' }} href="https://media.tiralarcbondues.fr/documents/fiche_inscription_2024_2025.pdf">au format pdf</a><br/>
              - <a style={{ fontWeight: 'bold' }} href="https://media.tiralarcbondues.fr/documents/fiche_inscription_2024_2025.xls">au format excel</a><br/>
              - <a style={{ fontWeight: 'bold' }} href="https://media.tiralarcbondues.fr/documents/questionnaire_sante.pdf">Le questionnaire santé</a><br/>
              - <a style={{ fontWeight: 'bold' }} href="https://media.tiralarcbondues.fr/documents/fiche_intervention_medicale.pdf">Pour les mineurs, la fiche d'intervention médicale</a><br/>
            </p>
            <p>
              Le club accueille tout les archers confirmés ou non à partir de 9 ans, quel que soit leur arme (arc classique, barebow, arc à poulies...).
            </p>
          </Grid>
        </CardCustom>
        <CardCustom sx={{ marginTop: theme.card.defaultPadding * 2 + "px" }}>
          <Grid container direction="column">
            <Typography variant="h4" fontWeight="bold">
              Faire un essai
            </Typography>
            <p>
              Si vous voulez faire un essai, vous pouvez renseigner les informations ci dessous :
            </p>
            <FormTextfield color="secondary" label='Votre prénom' variant='standard' value={name} onChange={(event) => setName(event.target.value)} />
            <FormTextfield color="secondary" label='Si vous voulez nous laisser un message' multiline minRows={3} variant='standard' value={description} onChange={(event) => setDescription(event.target.value)} />
            <FormTextfield color="secondary" type='number' label='Votre âge ou pour votre enfant son âge' variant='standard' value={age} onChange={(event) => setAge(event.target.value)} />
            <FormTextfield color="secondary" label='Un téléphone si vous souhaitez être rappelé directement' variant='standard' value={phone} onChange={(event) => setPhone(event.target.value)} />
            <FormTextfield color="secondary" label='Un email pour vous contacter (sans réponse au bout de 7 jours, vérifiez vos spams)' variant='standard' value={email} onChange={(event) => setEmail(event.target.value)} />
            {email !== '' && validateEmail(email) ?
              <Typography sx={{ color: 'red' }}>
                L'email est incorrect
              </Typography>
              : <></>}
            <Button onClick={sendMeetupRequest} variant="contained" disabled={name === '' || age === '' || email === '' || (email !== '' && validateEmail(email))} sx={{ backgroundColor: 'green' }}>
              Valider
            </Button>
          </Grid>
        </CardCustom>
      </GridRow>
    </Grid>
  )
}

export default Join
