import { styled } from '@mui/system';
import theme from './mainTheme';
import {
  Card,
  Divider,
  Grid
} from '@mui/material';

const DividerCustom = styled(Divider)({
  margin: "8px 0px",
  width: '100%'
});

const GridRow = styled(Grid)({
  padding: theme.card.defaultPadding + "px",
  [theme.breakpoints.down('md')]: {
    padding: theme.card.defaultPadding + "px 0px"
  },
});

const PrelineText = styled('div')({
  whiteSpace: "pre-line"
});

const CardCustom = styled(Card)({
  backgroundColor: '#eee',
  boxShadow: 'none',
  padding: '10px',
  borderRadius: 5
});

const RightTextGrid = styled(Grid)({
  textAlign: 'right'
})

export { DividerCustom, GridRow, PrelineText, CardCustom, RightTextGrid }