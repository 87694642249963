// #region imports
import { React, useState, useEffect } from 'react';
import moment from 'moment';
import {
  Grid,
  Box,
  Button,
  Typography
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { GridRow, CardCustom, DividerCustom } from '../style/componentsTheme';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// #endregion

// #region style
import { styled } from '@mui/system';

const CalendarCustom = styled(Calendar)({
  backgroundColor: '#eee',
  width: "100%",
  padding: '5px',
  border: '2px solid #bbb',
  borderRadius: 5,
  marginBottom: '5px',
  '& .react-calendar__month-view__days__day--weekend': {
    color: 'black'
  }
});

const SuscribeButton = styled(Button)({
  backgroundColor: 'Green',
  width: "100%"
});

// #endregion

function Reservation(props) {

  const [schedules, setSchedules] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [currentSchedule, setCurrentSchedule] = useState(null)
  const [currentBooking, setCurrentBooking] = useState(null)
  const [tabIndex, setTabIndex] = useState('1');

  const [doOnce, setDoOnce] = useState(true)

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      props.httpcall("get", "Schedule/Get", null, (input) => setSchedules(input))
    }
  }, [doOnce]);

  useEffect(() => {
    if (currentSchedule) {
      props.httpcall("get", "Booking/" + moment(selectedDate).format('DD-MM-YYYY') + "/" + currentSchedule.location + "/" + currentSchedule.start, null, (input) => setCurrentBooking(input))
    }
  }, [currentSchedule]);

  function dateChange(date) {
    setSelectedDate(date)
    setCurrentSchedule(null)
    setCurrentBooking(null)
  }

  function bookSelf() {
    props.httpcall("post", "Booking/" + moment(selectedDate).format('DD-MM-YYYY') + "/" + currentSchedule.location + "/" + currentSchedule.start, null, callbackBook)
  }

  function callbackBook() {
    props.statusUpdate("success", "Vous êtes inscrit(e) !")
    props.httpcall("get", "Booking/" + moment(selectedDate).format('DD-MM-YYYY') + "/" + currentSchedule.location + "/" + currentSchedule.start, null, (input) => setCurrentBooking(input))
  }

  return (
    <Grid container justifyContent="center">
      <GridRow item md={10} xs={12}>
        <Grid container justifyContent="space-around">
          <Grid item md={5} xs={12}>
            <CardCustom>
              <Grid container direction="column" alignItems="center">
                <Typography variant='h5'>
                  {selectedDate ? moment(selectedDate).format('DD/MM/YYYY') : "Pas de date sélectionnée"}
                </Typography>
                <CalendarCustom onChange={date => { dateChange(date) }} />
              </Grid>
            </CardCustom>
          </Grid>
          <Grid item md={6} xs={12}>
            <CardCustom>
              {selectedDate && schedules ?
                <TabContext value={tabIndex}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
                    <TabList onChange={(event, newValue) => { setTabIndex(newValue); setCurrentSchedule(null) }}>
                      <Tab label="Salle" value="1" />
                      <Tab label="Le fort" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        {schedules.filter(s => s.location === "Salle" && s.day === (selectedDate.getDay() || 7) - 1).length > 0 ?
                          <Typography variant='h5'>
                            Horaire(s) :
                          </Typography>
                          : <Typography variant='h5' fontStyle="italic">
                            Pas d'horaires
                          </Typography>
                        }
                      </Grid>
                      <>
                        {schedules.filter(s => s.location === "Salle" && s.day === (selectedDate.getDay() || 7) - 1).map((schedule) => (
                          <Grid item key={schedule.id}>
                            <Button variant="contained"
                              style={{ backgroundColor: currentSchedule && currentSchedule.id === schedule.id ? 'orange' : 'lightblue' }}
                              onClick={() => setCurrentSchedule(schedule)}>
                              {schedule.start}
                            </Button>
                          </Grid>
                        ))}
                      </>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                      {schedules.filter(s => s.location === "Le Fort" && s.day === (selectedDate.getDay() || 7) - 1).length > 0 ?
                          <Typography variant='h5'>
                            Horaire(s) :
                          </Typography>
                          : <Typography variant='h5' fontStyle="italic">
                            Pas d'horaires
                          </Typography>
                        }
                      </Grid>
                      <>
                        {schedules.filter(s => s.location === "Le Fort" && s.day === (selectedDate.getDay() || 7) - 1).map((schedule) => (
                          <Grid item key={schedule.id}>
                            <Button variant="contained"
                              style={{ backgroundColor: currentSchedule && currentSchedule.id === schedule.id ? 'orange' : 'lightblue' }}
                              onClick={() => setCurrentSchedule(schedule)}>
                              {schedule.start}
                            </Button>
                          </Grid>
                        ))}
                      </>
                    </Grid>
                  </TabPanel>
                </TabContext>
                : <Typography fontStyle="italic">
                  rien à afficher
                </Typography>
              }
              {currentSchedule ?
                <>
                  <Typography fontStyle="italic" variant="h6">
                    {currentSchedule.description} {currentSchedule.time}
                  </Typography>
                  <DividerCustom />
                  {currentBooking && currentBooking.names?.length > 0 ?
                    <Grid container direction="column">
                      <Typography>
                        Inscrit(s) :
                      </Typography>
                      {currentBooking.names.map(name => (
                        <Typography variant="h6" key={name}>
                          {name}
                        </Typography>
                      ))
                      }
                    </Grid>
                    : <Typography fontStyle="italic">
                      Pas d'inscrits
                    </Typography>
                  }
                  <DividerCustom />
                  <SuscribeButton variant="contained" onClick={() => bookSelf()}>
                    S'inscrire
                  </SuscribeButton>
                </>
                : <></>}
            </CardCustom>
          </Grid>
        </Grid>
      </GridRow>
    </Grid>
  )
}

export default Reservation;