// #region imports
import { React, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { GridRow, CardCustom, DividerCustom, RightTextGrid } from '../style/componentsTheme';
import moment from 'moment';

// #endregion

// #region style
import { styled } from '@mui/system';

const SliderCustom = styled(Slider)({
  margin: '10px 0px'
});

const AddButton = styled(Button)({
  margin: '10px 0px',
  width: '100%',
  textTransform: 'none'
});

// #endregion

const ArrowColors = ['#7A0086', 'white', 'white', '#575659', '#575659', '#0389FC', '#0389FC', 'red', 'red', '#FFE703', '#FFE703']

function Serie(props) {

  const [arrow1, setArrow1] = useState(10)
  const [arrow2, setArrow2] = useState(10)
  const [arrow3, setArrow3] = useState(10)

  const [currentSerie, setCurrentSerie] = useState([])

  function deleteLast() {
    let temp = [...currentSerie]
    temp.splice(currentSerie.length - 1, 1)
    setCurrentSerie(temp)
  }

  function addSerie() {
    let temp = [...currentSerie]
    temp.push([arrow1, arrow2, arrow3])

    setArrow1(10)
    setArrow2(10)
    setArrow3(10)

    setCurrentSerie(temp)
  }

  function GetIndexSummary(index) {
    var temp = 0
    for (let i = 0; i <= index; i++) {
      temp += currentSerie[i][0] + currentSerie[i][1] + currentSerie[i][2]
    }
    return temp
  }

  function handleNaN(input) {
    return input.toString() === "NaN" ? "0" : input
  }

  const [dialog, setDialog] = useState(false)
  const [ispublic, setIspublic] = useState(false)
  const [location, setLocation] = useState('')
  const [description, setDescription] = useState('')
  const [date, setDate] = useState(moment(new Date()).format('DD/MM/YYYY'))

  function saveCurrent() {
    props.httpcall("post", "Result/Add", { date: moment(date, 'DD/MM/YYYY').toDate(), location, description, public: ispublic, arrows: currentSerie, owner: "", id: "" }, callbackSave)
  }

  function callbackSave() {
    props.statusUpdate("success", "Série sauvegardée !")
    setDialog(false)
    setCurrentSerie([])
    setIspublic(false)
    setLocation('')
    setDescription('')
    setDate(moment(new Date()).format('DD/MM/YYYY'))
    setArrow1(10)
    setArrow2(10)
    setArrow3(10)
  }

  return (
    <>
      <Grid container justifyContent="center">
        <GridRow item md={11} xs={12}>
          <Grid container justifyContent="space-around">
            <GridRow item md={4} xs={12}>
              <CardCustom>
                <Grid container direction="column" alignItems="center" sx={{ padding: '15px' }}>
                  <Typography variant='h6' fontWeight='bold'>
                    Volée : {arrow1 + arrow2 + arrow3} / 30
                  </Typography>
                  <SliderCustom min={0} max={10} value={arrow1}
                    aria-label="Small" valueLabelDisplay="auto" onChange={(event) => setArrow1(event.target.value)}
                    sx={{ color: ArrowColors[arrow1] }} />
                  <SliderCustom min={0} max={10} value={arrow2}
                    aria-label="Small" valueLabelDisplay="auto" onChange={(event) => setArrow2(event.target.value)}
                    sx={{ color: ArrowColors[arrow2] }} />
                  <SliderCustom min={0} max={10} value={arrow3}
                    aria-label="Small" valueLabelDisplay="auto" onChange={(event) => setArrow3(event.target.value)}
                    sx={{ color: ArrowColors[arrow3] }} />
                  <DividerCustom />
                  <AddButton onClick={addSerie} variant='contained'>
                    Ajouter
                  </AddButton>
                  <DividerCustom />
                  <AddButton onClick={deleteLast} variant='contained' color="secondary" disabled={currentSerie.length === 0}>
                    Supprimer la dernière volée
                  </AddButton>
                </Grid>
              </CardCustom>
            </GridRow>
            <GridRow item md={4} xs={12}>
              <CardCustom>
                <Table size="small" >
                  <TableHead sx={{backgroundColor: 'lightblue'}}>
                    <TableRow>
                      <TableCell>
                        N°
                      </TableCell>
                      <TableCell align="center">
                        F 1
                      </TableCell>
                      <TableCell align="center">
                        F 2
                      </TableCell>
                      <TableCell align="center">
                        F 3
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentSerie.map((serie, index) => (
                      <TableRow key={index} sx={{ backgroundColor: 'white' }}>
                        <TableCell>
                          {index + 1}
                        </TableCell>
                        {serie.map((arrow, index) => (
                          <TableCell key={index} align="center" sx={{ backgroundColor: ArrowColors[arrow] }}>
                            {arrow}
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          {serie[0] + serie[1] + serie[2]}
                        </TableCell>
                        <TableCell align="right" sx={{ backgroundColor: 'lightblue' }}>
                          {GetIndexSummary(index)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardCustom>
            </GridRow>
            <GridRow item md={4} xs={12}>
              <CardCustom>
                <Grid container direction="column" alignItems="center">
                  <Typography variant='h6' fontWeight='bold'>
                    Score
                  </Typography>
                  <DividerCustom />
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant='h6'>
                        Total:
                      </Typography>
                    </Grid>
                    <RightTextGrid item xs={6}>
                      <Typography variant='h5'>
                        {GetIndexSummary(currentSerie.length - 1)} / {currentSerie.length * 30}
                      </Typography>
                    </RightTextGrid>
                    <Grid item xs={9}>
                      <Typography variant='h6'>
                        Nombre de volée(s):
                      </Typography>
                    </Grid>
                    <RightTextGrid item xs={3}>
                      <Typography variant='h5'>
                        {currentSerie.length}
                      </Typography>
                    </RightTextGrid>
                    <Grid item xs={9}>
                      <Typography variant='h6'>
                        Score moyen par volée(s):
                      </Typography>
                    </Grid>
                    <RightTextGrid item xs={3}>
                      <Typography variant='h5'>
                        {handleNaN((GetIndexSummary(currentSerie.length - 1) / currentSerie.length).toFixed(1))}
                      </Typography>
                    </RightTextGrid>
                    <Grid item xs={9}>
                      <Typography variant='h6'>
                        Score moyen par flèches:
                      </Typography>
                    </Grid>
                    <RightTextGrid item xs={3}>
                      <Typography variant='h5'>
                        {handleNaN((GetIndexSummary(currentSerie.length - 1) / currentSerie.length / 3).toFixed(1))}
                      </Typography>
                    </RightTextGrid>
                  </Grid>
                  <DividerCustom />
                  <AddButton onClick={() => setDialog(true)} variant='contained' disabled={currentSerie.length === 0}>
                    Sauvegarder
                  </AddButton>
                </Grid>
              </CardCustom>
            </GridRow>
          </Grid>
        </GridRow>
      </Grid>
      <Dialog open={dialog} onClose={() => setDialog(false)} PaperComponent={Paper}>
          <Grid container direction="column" sx={{ padding: '15px', minWidth: '350px' }}>
            <TextField label="Localisation (facultatif)" rows="2" value={location} onChange={(event) => setLocation(event.target.value)} />
            <DividerCustom />
            <TextField multiline label="Description (facultatif)" rows="2" value={description} onChange={(event) => setDescription(event.target.value)} />
            <DividerCustom />
            <TextField label="Date" value={date} onChange={(event) => setDate(event.target.value)} error={!moment(date, 'DD/MM/YYYY', true).isValid()} />
            <FormControlLabel control={<Checkbox size="large" value={ispublic} onChange={(event => setIspublic(event.target.checked))} />} label="Série publique ?" />
            <DividerCustom />
            <AddButton variant='contained' color="primary" disabled={!moment(date, 'DD/MM/YYYY', true).isValid()} onClick={saveCurrent}>
              Valider
            </AddButton>
          </Grid>
      </Dialog>
    </>
  )
}

export default Serie;