// #region imports
import { React, useState, useEffect } from 'react'
import {
  Grid,
  Typography
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { GridRow } from '../style/componentsTheme'
import Schedule from '../components/Schedule';
import EventCalendar from '../components/EventCalendar';
import { DividerCustom, PrelineText, CardCustom } from '../style/componentsTheme'

// #endregion

function Access(props) {

  const [doOnce, setDoOnce] = useState(true)
  const [indoor, setIndoor] = useState([])
  const [outdoor, setOutdoor] = useState([])

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      props.httpcall("get", "Schedule/Get", null, update)
    }
  }, [doOnce]);

  function update(input) {
    setIndoor(input.filter(date => date.location === "Salle"))
    setOutdoor(input.filter(date => date.location === "Le Fort"))
  }

  return (
    <Grid container justifyContent="center">
      <GridRow item xs={12} md={2}>
        <CardCustom>
          <EventCalendar {...props} />
        </CardCustom>
      </GridRow>
      <GridRow item xs={12} md={5}>
        <CardCustom>
          <Grid container direction="column">
            <Typography variant="h5" color="primary">
              Tir en Salle
            </Typography>
            <DividerCustom />
            <PrelineText>
              {"Adresse : 6 Chemin Saint-Georges, 59910 Bondues\nComplexe sportif Alain Poher\nLes séances se déroulent dans la salle 'La Poly'"}
            </PrelineText>
            <DividerCustom />
            <img src="https://media.tiralarcbondues.fr/assets/salletir.png" width="100%" alt="image" />
            <DividerCustom />
            <img src="https://media.tiralarcbondues.fr/assets/plansalle.png" width="100%" alt="image" />
            <DividerCustom />
            <Schedule schedules={indoor} />
          </Grid>
        </CardCustom>
      </GridRow>
      <GridRow item xs={12} md={5}>
        <CardCustom>
          <Grid container direction="column">
            <Typography variant="h5" color="primary">
              Tir en extérieur
            </Typography>
            <DividerCustom />
            <PrelineText>
              {"Adresse : 6 Chemin Saint-Georges, 59910 Bondues\nSuivre le chemin bleu sur le plan\nLe terrain est disponible a tout moment de la semaine"}
            </PrelineText>
            <DividerCustom />
            <img src="https://media.tiralarcbondues.fr/assets/terraintir.png" width="100%" alt="image" />
            <DividerCustom />
            <Schedule schedules={outdoor} />
          </Grid>
        </CardCustom>
      </GridRow>
    </Grid>
  )
}

export default Access
