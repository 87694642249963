// #region imports
import { React } from 'react'
import {
  Grid,
  Typography
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { DividerCustom, CardCustom } from '../style/componentsTheme'

// #endregion

// #region style
import { styled } from '@mui/system';
import theme from '../style/mainTheme';

// #endregion

function Article({ article }) {

//console.log(article)

  return (
    <CardCustom>
      <Grid container direction="column">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="h4" sx={{ textDecoration: 'underline' }}>
                  {article.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" sx={{ fontStyle: 'italic' }}>
                  - de {article.author}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              le {article.date}
            </Typography>
          </Grid>
        </Grid>
        <DividerCustom />
        <div style={{whiteSpace: "pre-line"}}>
          {article.content}
          <br/>
          {article.links?.map(link => (
            <a key={link.label} href={link.url}>{link.label}</a>
            ))}
            <br/>
            <br/>
          {article.imgs?.map(url => (
            <img key={url} src={url} style={{maxHeight: '600px'}} />
          ))}
        </div>
      </Grid>
    </CardCustom>
  )
}

export default Article;