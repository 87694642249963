// #region imports
import { React, useState } from 'react'
import {
  Grid,
  Box,
  Typography
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { GridRow, CardCustom } from '../style/componentsTheme';

// #endregion

// #region style
import { styled } from '@mui/system';
import theme from '../style/mainTheme';

// #endregion

function Club(props) {

  return (
    <Grid container justifyContent="center">
      <GridRow item md={10} xs={12}>
        <CardCustom>
          <Grid container direction="column">
            <Typography variant="h4" fontWeight="bold">
              En Construction
            </Typography>
          </Grid>
        </CardCustom>
      </GridRow>
    </Grid>
  )
}

export default Club
