// #region imports
import { React, useState } from 'react'
import {
  Grid,
  Box,
  Typography
} from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { GridRow, CardCustom } from '../style/componentsTheme';
import Sponsor from '../components/Sponsor';
import Article from '../components/Article';

// #endregion

// #region style
import { styled } from '@mui/system';
import theme from '../style/mainTheme';

// #endregion

function Home(props) {

  const [articles, setArticles] = useState([
    {
      author: 'Thomas',
      date: "05/07/2023",
      imgs: [],
      links: [],
      title: "Inscription 2024",
      content: "Bonjour à tous"
      + "\nLa fiche d'inscription pour la saison 2024-2025 est disponible dans la section 'Nous Rejoindre'"
      + "\n\nBonne flèches,"
      + "\nThomas"
    }
  ])

  return (
    <Grid container justifyContent="center">
      <GridRow item md={10} xs={12}>
        <CardCustom>
          <Grid container direction="column">
            <Typography variant="h4" fontWeight="bold" textAlign='center' gutterBottom>
              Bonjour, et bienvenue sur le site du club des archers de Bondues
            </Typography>
            <Typography variant="h5" sx={{ marginTop: '10px' }}>
              Le club de tir à l'arc de Bondues est un club de loisir qui possède un pas de tir en salle et à l'extérieur. Son président est Thibault Delegrange
            </Typography>
            <Typography variant="h6" sx={{ marginTop: '5px' }}>
              Si vous voulez venir essayer le tir à l'arc dans notre club, ou si vous voulez obtenir des informations sur les inscriptions, cliquez <a href='/NousRejoindre'>ici</a>
            </Typography>
            <Typography variant="h6">
              Si vous voulez savoir comment accéder à nos installations, cliquez <a href='/NousTrouver'>ici</a>
            </Typography>
          </Grid>
        </CardCustom>
        <CardCustom sx={{ marginTop: theme.card.defaultPadding * 2 + "px" }}>
          <Grid container direction="column">
            <Typography variant="h5" fontWeight="bold">
              Mot du président
            </Typography>
            <Typography variant="h6" sx={{ marginTop: '10px' }}>
             La compagnie des archers St Sébastien de Bondues est un club de loisir destiné à promouvoir la pratique du tir à l’arc et de partager un moment convivial sans le stress de la recherche de performance pour la compétition.
            </Typography>
            <Typography variant="h6" sx={{ marginTop: '10px' }} textAlign='end'>
              signé Thibault Delegrange
            </Typography>
          </Grid>
        </CardCustom>
        <CardCustom sx={{ marginTop: theme.card.defaultPadding * 2 + "px" }}>
          <Box sx={{ fontStyle: 'italic'}}>
            Vous trouverez ci dessous les dernières informations et actualités du club ici :
          </Box>
        </CardCustom>
        {articles?.map(article => (
          <Box key={article.title} sx={{ marginTop: theme.card.defaultPadding * 2 + "px" }}>
            <Article article={article} />
          </Box>
        ))}
      </GridRow>
      <GridRow item md={1} xs={12}>
        <CardCustom>
          <Sponsor />
        </CardCustom>
      </GridRow>
    </Grid>
  )
}

export default Home
