import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fcd200',
      contrastText: 'white',
    },
    secondary: {
      main: '#FF7F00',
      contrastText: 'white',
    }
  },
  appBar: {
    height: '50px'
  },
  card: {
    defaultPadding: 5
  }
});

export default theme;