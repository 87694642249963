// #region imports
import { React, useState, useEffect } from 'react'
import {
  Grid,
  Box,
  Typography
} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { DividerCustom } from '../style/componentsTheme';

// #endregion

// #region style
import { styled } from '@mui/system';

const CalendarCustom = styled(Calendar)({
  backgroundColor: '#eee',
  width: "100%",
  padding: '5px',
  border: '2px solid #bbb',
  borderRadius: 5,
  marginBottom: '5px',
  '& .react-calendar__month-view__days__day--weekend': {
    color: 'black'
  }
});

const ScheduleTypography = styled(Typography)({
  marginBottom: '0px'
});

// #endregion

function EventCalendar(props) {

  const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]

  const [schedules, setSchedules] = useState(null)
  const [doOnce, setDoOnce] = useState(true)
  const [indoor, setIndoor] = useState([])
  const [outdoor, setOutdoor] = useState([])

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      setSelectedDate((new Date().getDay() || 7) - 1)
      props.httpcall("get", "Schedule/Get", null, (input) => { setSchedules(input); dateChange(new Date())})
    }
  }, [doOnce]);

  const [selectedDate, setSelectedDate] = useState(null)

  function dateChange(event) {
    setSelectedDate((event.getDay() || 7) - 1)
  }

  useEffect(() => {
    if (selectedDate != null && schedules) {
      setIndoor(schedules.filter(date => date.day === selectedDate && date.location === "Salle"))
      setOutdoor(schedules.filter(date => date.day === selectedDate && date.location === "Le Fort"))
    }
  }, [selectedDate, schedules]);

  return (
    <Grid container direction="column">
      <CalendarCustom onChange={dateChange} />
      <DividerCustom />
      <>
        <Typography variant="h5" color="primary">
          Horaire du {days[selectedDate]}
        </Typography>
        <Grid container direction="column">
          <Typography color="secondary">
            Salle
          </Typography>
          {indoor.length > 0 ?
            <>
              {
                indoor.map(date => (
                  <Grid container direction="column" key={date.id}>
                    <ScheduleTypography>
                      {date.time}
                    </ScheduleTypography>
                    <Typography fontStyle="italic">
                      {date.description}
                    </Typography>
                  </Grid>
                ))
              }
            </>
            : <Box sx={{ fontStyle: 'italic' }}>
              Pas d'horaires
            </Box>}
          <Typography color="secondary">
            Le Fort
          </Typography>
          {indoor.length > 0 ?
            <>
              {outdoor.map(date => (
                <Grid container direction="column" key={date.id}>
                  <ScheduleTypography>
                    {date.time}
                  </ScheduleTypography>
                  <Typography fontStyle="italic">
                    {date.description}
                  </Typography>
                </Grid>
              ))}
            </>
            : <Box sx={{ fontStyle: 'italic' }}>
              Pas d'horaires
            </Box>
          }
        </Grid>
      </>
    </Grid>
  )
}

export default EventCalendar;