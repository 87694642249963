// #region imports
import { React} from 'react'
import { Grid, Box } from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import { DividerCustom } from '../style/componentsTheme';

// #endregion

function Sponsor() {

  return (
    <Grid container direction="column">
      <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
        Le Club
      </Box>
      <DividerCustom />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={12} xs={5}>
          <img src="https://media.tiralarcbondues.fr/assets/bonduestaaLogo.png" width="100%" alt="image" />
        </Grid>
      </Grid>
      <DividerCustom />
      <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
        Affiliations
      </Box>
      <DividerCustom />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={12} xs={5}>
          <img src="https://media.tiralarcbondues.fr/assets/bondueslogo.png" width="100%" alt="image" />
        </Grid>
        <Grid item md={12} xs={0}>
          <DividerCustom />
        </Grid>
        <Grid item md={12} xs={5}>
          <img src="https://media.tiralarcbondues.fr/assets/fftalogo.png" width="100%" alt="image" />
        </Grid>
        <Grid item md={12} xs={12}>
          <DividerCustom />
        </Grid>
        <Grid item md={12} xs={5}>
          <img src="https://media.tiralarcbondues.fr/assets/cdntalogo.png" width="100%" alt="image" />
        </Grid>
        <Grid item md={12} xs={0}>
          <DividerCustom />
        </Grid>
        <Grid item md={12} xs={5}>
          <img src="https://media.tiralarcbondues.fr/assets/hdflogo.png" width="100%" alt="image" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Sponsor;