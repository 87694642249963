// #region imports
import { React, useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { GridRow, CardCustom, DividerCustom } from '../style/componentsTheme';
import 'react-calendar/dist/Calendar.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// #endregion

// #region style
import { styled } from '@mui/system';

const LightTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    padding: '8px',
    height: '21px',
    backgroundColor: 'white'
  }
});

// #endregion

function Account(props) {
  const [doOnce, setDoOnce] = useState(true)

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      props.httpcall("get", "spec/Get", null, (input) => setSpecs(input))
    }
  }, [doOnce]);

  const [specs, setSpecs] = useState(null)
  const [newArrow, setNewArrow] = useState(null)

  function addArrow() {
    props.httpcall("post", "spec/Add", { id: "", owner: "", arrowName: newArrow, specs: [] }, callbackArrow)
  }

  function callbackArrow() {
    setNewArrow(null)
    setNewDistance(null)
    setNewDistanceName(null)
    props.httpcall("get", "spec/Get", null, (input) => setSpecs(input))
  }

  const [newDistanceName, setNewDistanceName] = useState(null)
  const [newDistance, setNewDistance] = useState(null)

  function addSpec(index) {
    let temp = [...specs]
    if (!temp[index].specs) {
      temp[index].specs = [[newDistanceName, newDistance]]
    } else {
      temp[index].specs.push([newDistanceName, newDistance])
    }
    props.httpcall("post", "spec/Update", temp[index], callbackArrow)
  }

  function updateDistance(event, index, subIndex) {
    let temp = [...specs]
    temp[index].specs[subIndex][1] = event.target.value
    setSpecs(temp)
  }

  function deleteDistance(index, subIndex) {
    let temp = [...specs]
    temp[index].specs.splice(subIndex, 1)
    props.httpcall("post", "spec/Update", temp[index], callbackArrow)
  }

  return (
    <Grid container justifyContent="center">
      <GridRow item md={10} xs={12}>
        <CardCustom>
          <Typography variant='h5' gutterBottom fontStyle='Italic'>
            Mes réglages :
          </Typography>
          {specs && specs.length > 0 ?
            <>
              {specs.map((spec, index) => (
                <Accordion key={spec.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{spec.arrowName} - {spec.specs ? spec.specs.length : '0'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {spec.specs && spec.specs.length > 0 ?
                      <>
                        <Table size="small" >
                          <TableHead sx={{ backgroundColor: 'lightblue' }}>
                            <TableRow>
                              <TableCell>
                                Distance
                              </TableCell>
                              <TableCell>
                                Réglage
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {spec.specs.map((setup, subIndex) => (
                              <TableRow key={subIndex}>
                                <TableCell>
                                  {setup[0]}m
                                </TableCell>
                                <TableCell>
                                  <LightTextField type="number" value={setup[1] ?? ''} onChange={(event) => { updateDistance(event, index, subIndex) }} />
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton sx={{ color: 'red' }} onClick={() => { deleteDistance(index, subIndex) }} >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Button variant='contained' sx={{ width: '100%' }} onClick={() => { props.httpcall("post", "spec/Update", spec, callbackArrow) }}>
                          Sauvegarder
                        </Button>
                      </>
                      :
                      <Typography fontStyle="italic">
                        Pas de réglages enregistrés pour le moment
                      </Typography>}
                    <DividerCustom />
                    <Grid container justifyContent='space-between'>
                      <Grid item xs={4}>
                        <LightTextField fullWidth placeholder='Distance' type="number" value={newDistanceName ?? ''} onChange={(event) => { setNewDistanceName(event.target.value) }} />
                      </Grid>
                      <Grid item xs={4}>
                        <LightTextField fullWidth placeholder='Valeur' type="number" value={newDistance ?? ''} onChange={(event) => { setNewDistance(event.target.value) }} />
                      </Grid>
                      <Grid item xs={3}>
                        <Button variant='contained' onClick={() => addSpec(index)} disabled={!newDistance || newDistance === '' || !newDistanceName || newDistanceName === ''}>
                          Ajouter
                        </Button>
                      </Grid>
                    </Grid>
                    <DividerCustom />
                    <Button variant='contained' color="secondary" sx={{ width: '100%' }} onClick={() => { props.httpcall("delete", "spec/Delete/" + spec.id, null, callbackArrow) }}>
                      Supprimer la flèche
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
            :
            <Typography fontStyle="italic">
              Pas de flèches enregistrées pour le moment
            </Typography>}
          <DividerCustom />
          <Grid container>
            <Grid item xs={8}>
              <LightTextField fullWidth placeholder='Nom Flèche' value={newArrow ?? ''} onChange={(event) => { setNewArrow(event.target.value) }} />
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={1}>
              <Button variant='contained' onClick={addArrow} disabled={!newArrow || newArrow === ''}>
                Ajouter
              </Button>
            </Grid>
          </Grid>
        </CardCustom>
      </GridRow>
    </Grid >
  )
}

export default Account;